<template>
  <div
    v-show="open"
    :id="id"
    class="absolute z-10 min-w-max w-full mt-1 bg-white rounded shadow overflow-y-auto max-h-56 border scroll-snap text-black"
    :class="[
      parentProps?.position === 'left' ? 'left-0' : 'right-0',
      !parentProps?.ignoreDarkMode && 'dark:bg-dark-mode-white dark:text-dark-mode-black'
    ]"
    role="listbox"
    :aria-label="ariaLabel"
    :aria-labelledby="ariaLabelledby"
    :aria-activedescendant="ariaActivedescendant"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
import { inject, ref } from 'vue'
import { SOpen, SDropdownProps } from './symbols'

defineProps({
  id: {
    type: String,
    default: '',
  },

  ariaLabel: {
    type: String,
    default: '',
  },

  ariaLabelledby: {
    type: String,
    default: '',
  },

  ariaActivedescendant: {
    type: String,
    default: '',
  },
})

const open = inject(SOpen, ref(false))
const parentProps = inject(SDropdownProps)
</script>

<style>
  .scroll-snap {
    scroll-snap-type: y mandatory;
  }
</style>
