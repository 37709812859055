import { ref, watch, onMounted } from 'vue'

export type ThemePreference = 'light' | 'dark' | 'auto'

const themePreference = ref<ThemePreference>(
  (localStorage.getItem('themePreference') as ThemePreference) || 'dark'
)

const isDark = ref(false)

const updateTheme = () => {
  const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches

  if (themePreference.value === 'auto') {
    isDark.value = prefersDark
  } else {
    isDark.value = themePreference.value === 'dark'
  }

  // Update the DOM
  if (isDark.value) {
    document.documentElement.classList.add('dark')
  } else {
    document.documentElement.classList.remove('dark')
  }
}

// Initialize theme immediately if we're in the browser
if (typeof window !== 'undefined') {
  updateTheme()
}

// Watch for theme preference changes
watch(themePreference, () => {
  localStorage.setItem('themePreference', themePreference.value)
  updateTheme()
})

// Watch for system theme changes
if (typeof window !== 'undefined') {
  const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)')

  const handleSystemThemeChange = (e: MediaQueryListEvent) => {
    if (themePreference.value === 'auto') {
      isDark.value = e.matches
      updateTheme()
    }
  }

  darkModeMediaQuery.addEventListener('change', handleSystemThemeChange)
}

export function useTheme () {
  // Initialize theme when component mounts
  onMounted(() => {
    updateTheme()
  })

  const setTheme = (theme: ThemePreference) => {
    themePreference.value = theme
    updateTheme()
  }

  return {
    themePreference,
    isDark,
    updateTheme,
    setTheme,
  }
}
