<template>
  <div class="flex w-full pt-1 lg:pt-3">
    <div
      class="grow items-center pb-2 flex-col h-full"
      tabindex="0"
    >
      <div
        v-if="isPaid || (supportAmount !== 0 && confirmFlow)"
        class="flex flex-col w-full h-32 lg:h-[190px] gap-2 lg:pb-0 mb-1"
      >
        <PriceSummary
          class="mb-2 lg:mb-3"
          :price="supportAmount"
          :chain-id="chainId"
          :show-arrow="confirmFlow"
          @back="handleBackFromConfirm"
        />
      </div>

      <div
        v-if="!isPaid && !confirmFlow && !mainSelectActive"
        class="flex flex-col w-full h-32 lg:h-[184px] gap-2 pb-2"
      >
        <div class="w-full h-full">
          <EditPrice
            :custom-amount="customValue"
            :max-amount="props.maxAmount"
            :custom-amount-increments="props.customAmountIncrements"
            :has-tap-clicked="hasTapClicked"
            :chain-id="chainId"
            :ignore-dark-mode="ignoreDarkMode"
            @back="handleBackFromCustom"
            @update="handleUpdateSupportAmount"
            @tap-clicked="handleTapClicked"
          />
        </div>

        <div
          v-if="hasTapClicked"
          class="w-full h-10 lg:h-14"
        >
          <CryptoPrice
            :chain-id="chainId"
            :price="customValue"
          />
        </div>
      </div>

      <div
        v-else-if="!isPaid && singlePrice === null && !endComplete && !confirmFlow"
        class="h-28 lg:h-[168px] pb-6 mb-4 lg:mb-4 border-b-[1px] border-brand-border"
      >
        <div class="flex flex-row pb-2 lg:pb-3 w-full gap-1 lg:gap-3 pt-0 lg:pt-1">
          <div
            v-for="amount in dollarAmounts"
            :key="amount"
            class="w-full"
          >
            <GlobalButton
              class="w-full active:outline-0 hover:outline-0 focus:outline-0 rounded-[12px] border-[1px]"
              :class="supportAmount === amount? '':'focus:border-brand-border'"
              :size="isMobile? 'base': 'base'"
              :priority="supportAmount === amount && !customActive? 'primary-dark': 'secondary-light'"
              :ignore-dark-mode="ignoreDarkMode"
              @click="selectSupportAmount(amount)"
            >
              {{ amount === 0 ? 'Free': `$${amount}` }}
            </GlobalButton>
          </div>
        </div>

        <div class="flex flex-row pb-2 lg:pb-3 w-full">
          <GlobalButton
            class="w-full active:outline-0 hover:outline-0 focus:outline-0 rounded-[12px] border-[1px]"
            :size="isMobile? 'base': 'base'"
            :priority="customActive? 'primary-dark': 'secondary-light'"
            :ignore-dark-mode="ignoreDarkMode"
            @click="handleCustomClick"
          >
            {{ customValue && customActive ? `Custom - $${customValue}` : 'Custom' }}
          </GlobalButton>
        </div>
      </div>

      <GlobalButton
        v-if="supportAmount === 0"
        class="w-full active:translate-y-[0] rounded-[12px] border-[1px]"
        priority="primary-light"
        :size="isMobile? 'base' : 'base'"
        :disabled="buttonDisabled"
        :ignore-dark-mode="ignoreDarkMode"
        @click.stop="emit('claim', supportAmount)"
      >
        {{ getButtonTitle }}

        <LoadingIcon
          v-if="loading"
          class="animate-spin w-4 h-4"
        />
      </GlobalButton>

      <GlobalButton
        v-else-if="confirmFlow"
        class="w-full rounded-[12px] border-[1px]"
        priority="primary-light"
        :size="isMobile? 'base' : 'base'"
        :disabled="buttonDisabled"
        :ignore-dark-mode="ignoreDarkMode"
        @click.stop="handleConfirmClick()"
      >
        Confirm and {{ isTipArtistFlow ? 'tip' : isDepositFlow ? 'deposit' : 'pay' }} - ${{ supportAmount }}
        <LoadingIcon
          v-if="loading"
          class="animate-spin w-4 h-4"
        />
      </GlobalButton>

      <div
        v-else
        class="flex flex-row gap-2 w-full pt-1 lg:pt-2"
      >
        <div class="w-full flex flex-col">
          <div class="flex flex-row gap-2 w-full">
            <GlobalButton
              v-if="!isDepositFlow"
              class="w-full active:translate-y-[0] rounded-[12px] border-[1px]"
              priority="primary-light"
              :size="isMobile? 'base' : 'base'"
              :disabled="buttonDisabled"
              :ignore-dark-mode="ignoreDarkMode"
              @click.stop="handleActionClick('claim-with-credit-card')"
            >
              Card
            </GlobalButton>

            <GlobalButton
              class="w-full active:translate-y-[0] rounded-[12px] border-[1px]"
              priority="primary-light"
              size="base"
              :disabled="buttonDisabled"
              :ignore-dark-mode="ignoreDarkMode"
              @click.stop="handleActionClick('claim-with-digital-wallet')"
            >
              {{ secondaryButtonText? secondaryButtonText : 'Wallet' }}
            </GlobalButton>

            <GlobalButton
              v-if="!isDepositFlow"
              class="w-full active:translate-y-[0] rounded-[12px] border-[1px]"
              priority="primary-light"
              :size="isMobile? 'base' : 'base'"
              :disabled="buttonDisabled"
              :ignore-dark-mode="ignoreDarkMode"
              @click.stop="handleActionClick('claim-with-balance')"
            >
              Balance
            </GlobalButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, PropType, computed } from 'vue'

import GlobalButton from '#components/global/global-button'
import LoadingIcon from '#components/icons/loading-icon.vue'
import PriceSummary from '#components/minting/price-summary.vue'
import EditPrice from '#components/minting/edit-price.vue'
import CryptoPrice from '#components/minting/crypto-price.vue'

const props = defineProps({
  loading: {
    type: Boolean,
    required: true,
  },

  title: {
    type: String as PropType<string | null>,
    default: null,
  },

  description: {
    type: String as PropType<string | null>,
    default: null,
    required: true,
  },

  buttonTitle: {
    type: String,
    required: true,
  },

  buttonDisabled: {
    type: Boolean,
    required: true,
  },

  dollarAmounts: {
    type: Array as PropType<number[]>,
    default: () => ([]),
  },

  customAmount: {
    type: Number,
    default: 5,
  },

  maxAmount: {
    type: Number,
    default: 1500,
  },

  customAmountIncrements: {
    type: Number,
    default: 1,
  },

  popular: {
    type: Number as PropType<number | null>,
    default: null,
  },

  singlePrice:{
    type: Number as PropType<number | null>,
    default: null,
  },

  endComplete: {
    type: Boolean,
    default: false,
  },

  dualButtonFlow: {
    type: Boolean,
    default: false,
  },

  secondaryButtonText: {
    type: String as PropType<string | null>,
    default: null,
  },

  hidePopular: {
    type: Boolean,
    default: false,
  },

  isDepositFlow: {
    type: Boolean,
    default: false,
  },

  isTipArtistFlow: {
    type: Boolean,
    default: false,
  },

  chainId: {
    type: Number as PropType<number | null>,
    default: null,
  },

  isPaid: {
    type: Boolean,
    default: false,
  },

  floorPrice: {
    type: Number,
    default: 0,
  },

  ignoreDarkMode: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['none', 'claim','claim-with-credit-card','claim-with-digital-wallet','claim-with-balance', 'selected-type'])

const supportAmount = ref(props.isPaid? props.floorPrice: props.singlePrice !== null ? props.singlePrice : props.popular || 0)
const mainSelectActive = ref(true)
const customActive = ref(false)
const confirmFlow = ref(false)
const hasTapClicked = ref(false)
const customValue = ref(props.customAmount)
const clickActionType = ref<'none' | 'claim' | 'claim-with-credit-card' | 'claim-with-digital-wallet' |'claim-with-balance'>('none')

const isMobile = computed(() => window.innerWidth < 768)

const selectSupportAmount = (amount: number) => {
  const allowZero = props.dollarAmounts.includes(0)
  supportAmount.value =  supportAmount.value === amount ? allowZero ? 0 : amount : amount

  if (props.dollarAmounts.includes(supportAmount.value)) {
    customActive.value = false
    mainSelectActive.value = true
  }
}

const handleTapClicked = () => hasTapClicked.value = true

const handleActionClick = (type) => {
  clickActionType.value = type
  confirmFlow.value = true

  emit('selected-type', type)
}

const handleConfirmClick = () => {
  confirmFlow.value = false
  emit(clickActionType.value, supportAmount.value)
}

const handleBackFromConfirm = () => {
  confirmFlow.value = false
}

const handleCustomClick = () => {
  customActive.value = true
  mainSelectActive.value = false
  supportAmount.value = customValue.value
}

const handleBackFromCustom = () => {
  customActive.value = true
  mainSelectActive.value = true
}

const handleUpdateSupportAmount = (amount: number) => {
  supportAmount.value = amount
  customValue.value = amount
  hasTapClicked.value = true
}

const getButtonTitle = computed(() => {
  if (props.endComplete) {
    return 'Collection Closed'
  }

  return supportAmount.value === 0 ? 'Collect - Free': `${props.buttonTitle} - $${supportAmount.value} USD`
})
</script>
