<template>
  <div
    class="relative z-20 bg-white dark:bg-black text-black dark:text-[#f6f6f6] transition-colors"
    :class="[
      mobileMenuIsOpen ? 'pt-14' : '',
      route.name === 'prototype' ? 'flex flex-col flex-grow flex-shrink-0 h-screen' : 'flex flex-col min-h-screen [&>div:not(.exclude)]:w-full'
    ]"
    @click="closeProfileMenu"
    @keyup.esc="closeProfileMenu"
  >
    <Modal
      :persistent="true"
      :is-open="connectModalState.matches('connecting')"
      :hide-action-buttons="true"
      :centered="true"
      :title="connectorId === 'crossMint' ? 'Sign into Crossmint' : 'Connect your wallet'"
      :subtitle="connectorId === 'crossMint' ? 'Sign into Crossmint with your email or social accounts to complete your login on remx.' : 'Connect your wallet to complete your login on remx.'"
    >
      <Body size="sm">
        Need help? <a
          class="underline cursor-pointer"
          @click="troubleshootWallet"
        > Check out our FAQ</a>
      </Body>
    </Modal>

    <Suspense>
      <GlobalHeader
        v-if="!hideGlobalHeader"
        :mobile-menu-is-open="mobileMenuIsOpen"
        :profile-menu-is-open="profileMenuIsOpen"
        :is-dark="route.matched.some(entry => entry.meta.darkNav)"
        :is-showroom="route.matched.some(entry => entry.meta.showroom)"
        :is-create-nav="route.matched.some(entry => entry.meta.createNav)"
        @open-mobile-menu="openMobileMenu"
        @close-mobile-menu="closeMobileMenu"
        @close-profile-menu="closeProfileMenu"
        @toggle-profile-menu="toggleProfileMenu"
        @open-deposit-modal="handleOpenDepositModal"
        @slide-to-wait-list="navigateTo( route.matched.some(entry => entry.meta.isLegacy) ? 'home-legacy' : 'home', '#wait-list-email-form')"
      />
    </Suspense>

    <Snackbar />

    <section
      class="w-full flex pt-0"
      :class="[!hideGlobalHeader ? 'md:pt-8' : '']"
    >
      <div
        v-if="!hideGlobalHeader"
        class="hidden md:block w-0 md:w-[300px] lg:w-[350px] sticky top-0"
      >
        <GlobalSideNav @open-deposit-modal="handleOpenDepositModal" />
      </div>

      <div class="w-full">
        <RouterView
          v-slot="{ Component }"
          class="relative z-0 grow flex flex-col mb-16 md:mb-0"
        >
          <transition
            enter-active-class="transition duration-300 ease-out"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition duration-300 ease-in"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
            mode="out-in"
          >
            <Suspense>
              <slot>
                <component :is="Component" />
              </slot>
            </Suspense>
          </transition>
          <AccountActivity
            v-if="isAuthenticated && accountId"
            class="sticky bottom-5 md:bottom-10"
          />
        </RouterView>
      </div>

      <GlobalReload v-if="reload" />

      <GlobalMobileFooterNav v-if="isAuthenticated && !hideGlobalHeader" />
    </section>

    <!-- <ReloadPrompt /> -->

    <Suspense v-if="route.name !== 'start-create-account'">
      <LoginModal @new-account-login="handleNewAccountLogin" />
    </Suspense>

    <Suspense>
      <XProfileModal
        :is-open="isXModalOpen"
        @close="closeConnectXModal"
      />
    </Suspense>

    <Suspense>
      <MintModal
        :show-countdown-timer="false"
        :is-open="depositModalIsOpen"
        @close="closeDepositModal"
      />
    </Suspense>

    <Suspense>
      <SwitchToCreateFlowModal
        :is-open="switchToCreateFlowModalIsOpen"
        :connection-type="loginConnectionType"
        @close="switchToCreateFlowModalIsOpen = false"
      />
    </Suspense>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, computed, onMounted, onBeforeMount } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useAccount as useWagmiAccount } from 'use-wagmi'
import { useGtag } from 'vue-gtag-next'
// import { useLazyQuery } from '@vue/apollo-composable'
// import { datadogRum } from '@datadog/browser-rum'

import { isMobile } from '#utils/global'

import useLogin from '#composables/use-login'
import { useLoginModal } from '#composables/use-login-modal'
import useAccount from '#composables/use-account'
import { useMutation, useLazyQuery } from '#composables/use-apollo'
import useConnectX from '#composables/use-connect-x'
import useVersionReloader from '#composables/use-version-reloader'
import usePendingMoment from '#composables/use-pending-moment'
import { useTheme } from '#composables/use-theme'

import { UPDATE_ACCOUNT } from '#mutations/UpdateAccount'
import type { UpdateAccount, UpdateAccountVariables } from '#graphql/types/UpdateAccount'

import { GET_PENDING_MOMENTS } from '#queries/GetPendingMoments'
import type { GetPendingMoments, GetPendingMomentsVariables } from '#graphql/types/GetPendingMoments'

import AccountActivity from '#components/global/account-activity.vue'
import Body from '#components/typography/body'
import Modal from '#components/modal'
import Snackbar from '#components/snackbar'
import GlobalHeader from '#components/global/global-header.vue'
import LoginModal from '#components/login-modal'
import XProfileModal from '#components/x-profile-modal/x-profile-modal.vue'
import GlobalSideNav from '#components/global/global-side-nav.vue'
import GlobalMobileFooterNav from '#components/global/global-mobile-footer-nav.vue'
import MintModal from '#components/minting/mint-modal.vue'
import SwitchToCreateFlowModal from '#components/switch-to-create-flow-modal/switch-to-create-flow-modal.vue'
import GlobalReload from '#components/global/global-reload.vue'

const {
  hasFarcaster,
  accountNotifications,
  profileSlug,
} = useAccount()
const { connector } = useWagmiAccount()
const {
  accountId,
  isAuthenticated,
  reset: resetAuth,
} = useLogin()
const { state: connectModalState, send } = useLoginModal()
const router = useRouter()
const route = useRoute()
const { config } = useGtag()
const {
  isOpen: isXModalOpen,
  closeConnectXModal,
} = useConnectX()
const { reload, htmlVersion, serverVersion } = useVersionReloader()
const { updateActiveMoment } = usePendingMoment()
const { isDark } = useTheme()

const mobileMenuIsOpen = ref(false)
const profileMenuIsOpen = ref(false)
const hasFarcasterAccount = computed(() => hasFarcaster.value)
const showFarcasterAddToAccountAnnouncement = ref(false)
const showFarcasterShareProfileAnnouncement = ref(false)
const depositModalIsOpen = ref(false)
const switchToCreateFlowModalIsOpen = ref(false)
const connectorId = computed(() => connector?.value?.id || '')
const loginConnectionType = ref<'wallet' | 'crossmint' | 'email'>('wallet')
const hideGlobalHeader = computed(() => route.matched.some(entry => entry.meta.hideGlobalHeader))

const openMobileMenu = () => mobileMenuIsOpen.value = true
const closeMobileMenu = () => mobileMenuIsOpen.value = false

const closeProfileMenu = () => profileMenuIsOpen.value = false
const toggleProfileMenu = () => profileMenuIsOpen.value = !profileMenuIsOpen.value

const closeDepositModal = () => depositModalIsOpen.value = false

const handleOpenDepositModal = () => {
  depositModalIsOpen.value = true
}

const navigateTo = (name: string, hash: string) => {
  router.push({ name, hash })
}

const troubleshootWallet = () => {
  resetAuth()
  closeMobileMenu()

  // @todo: this is a temporary workaround to get the page to refresh when
  // navigating to the FAQ page from the connect modal if you try to navigate
  // before you are fully signed in, or else  the connector will still be
  // running, and will not allow you to log in until you manually refresh the
  // page.
  const faqUrl = '/faq#accounts'

  if (route.name === 'faq') {
    window.location.reload()
  } else {
    window.location.href = faqUrl
  }

  send('CLOSE')
}

const handleNewAccountLogin = (connectionType: 'wallet' | 'crossmint' | 'email') => {
  loginConnectionType.value = connectionType
  switchToCreateFlowModalIsOpen.value = true
}

// We use this to load the pending moments so incase the user has active pending
// moments, but has refreshed the page which will cause the usePendingMoment
// composable to go back to its default valuse of false.  This will cause a
// global refetch of the pending moments, and update the active moment count so
// we dont have to wait for the activity monitor to change statuses first to
// take back over and update the composable.
const handleLoadPendingMoments = async () => {
  try {
    const result = await getPendingMoments({ input: { accountId: profileSlug.value } }, { fetchPolicy: 'no-cache' })

    if (result.data?.getPendingMoments) {
      updateActiveMoment(result.data.getPendingMoments.length)
    }
  } catch (error) {
    console.error('Error loading pending moments:', error)
  }
}

const {
  load: getPendingMoments,
  onResult: onPendingMomentsResult,
  onError: loadingPendingMoments,
} = useLazyQuery<GetPendingMoments, GetPendingMomentsVariables>(GET_PENDING_MOMENTS)

onPendingMomentsResult((result) => {
  if (result.data?.getPendingMoments) {
    updateActiveMoment(result.data.getPendingMoments.length)
  }
})

/**
 * Mutations.
 */

// Update Account
const {
  mutate: updateAccount,
  loading: updateAccountLoading,
  onResult: updateAccountOnResult,
  onError: updateAccountOnError,
} = useMutation<UpdateAccount, UpdateAccountVariables>(UPDATE_ACCOUNT)

updateAccountOnError((error) => {
  console.error('Error updating account:', error)
})

// When a user gets unauthenticated, make sure they're not on a protected route.
watch(
  isAuthenticated,
  (newAuth) => {
    if (!newAuth && route.meta.requiresAuthentication) {
      router.push({ name: 'home' } )
    }

    handleLoadPendingMoments()
  }
)

// update ctag config when account changes
watch(
  accountId,
  (newId) => {
    config({
      user_id: newId,
    })

    // if (import.meta.env.VITE_DD_ENVIRONMENT && import.meta.env.VITE_DD_CLIENT_TOKEN) {
    //   if (newId) {
    //     datadogRum.setUser({ id: newId })
    //   } else {
    //     datadogRum.clearUser()
    //   }
    // }
  }
)

watch(() => [hasFarcasterAccount.value, accountNotifications.value], ([hasFarcasterAccount, accountNotifications]) => {
  showFarcasterAddToAccountAnnouncement.value = !hasFarcasterAccount && (accountNotifications?.farcaster && !accountNotifications.farcaster.addToAccountDismissed)

  showFarcasterShareProfileAnnouncement.value = hasFarcasterAccount && (accountNotifications?.farcaster && !accountNotifications.farcaster.shareProfileDismissed)
}, { immediate: true })

onMounted(() => {
  const win: any = window

  if (typeof win.Featurebase !== 'function') {
    win.Featurebase = function (...args: any[]) {
      (win.Featurebase.q = win.Featurebase.q || []).push(...args)
    }
  }

  const featurebaseInput: any = {
    organization: 'remx', // Replace this with your organization name, copy-paste the subdomain part from your Featurebase workspace url (e.g. https://*yourorg*.featurebase.app)
    theme: 'dark',
    locale: 'en', // Change the language, view all available languages from https://help.featurebase.app/en/articles/8879098-using-featurebase-in-my-language
    defaultBoard: 'Feedback',
  }

  if (!isMobile.value) {
    // this is a hack to get the injected feedback button to only show on
    // desktop/tablet and not mobile
    featurebaseInput.placement = 'right'
  }

  win.Featurebase('initialize_feedback_widget', featurebaseInput)
  const metaElements = Array.from(document.getElementsByTagName('meta'))

  const filteredElements = metaElements.filter((el) => {
    return el.getAttribute('property') === 'x-version-hash'
  }).map((el) => el.getAttribute('content'))

  const versionHash = filteredElements.length > 0 ? filteredElements[0] : 'localhost'

  htmlVersion.value = versionHash || ''
  serverVersion.value = versionHash || ''
})

onBeforeMount(() => {
  const script = document.createElement('script')
  script.src = 'https://do.featurebase.app/js/sdk.js'
  script.id = 'featurebase-sdk'
  document.head.appendChild(script)
})
</script>

<style>
/* crossmint pay button doesn't seem to read its own props, so this hack is necessary */
div#__crossmint-overlay__ {
  display: none;
}

/* Add dark mode styles for the html and body */
:root {
  @apply bg-white text-black transition-colors;
}

:root.dark {
  @apply bg-black text-[#f6f6f6];
}
</style>
