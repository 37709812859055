<template>
  <div
    v-if="activity.length"
    id="account-activity-widget"
    class="hidden px-5 md:pl-10 mb-5 md:mb-10 w-full md:w-96 exclude"
  >
    <div
      class="w-full bg-black text-white rounded-lg transition-all duration-300 ease-in overflow-hidden p-6"
      :class="[expanded ? `max-h-[calc(140px + 40px*3)]` : 'max-h-20']"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch, type Ref } from 'vue'
import { useRouter } from 'vue-router'
import usePendingMoment from '#composables/use-pending-moment'

import useProviders from '#composables/use-providers'
import useSnackbar from '#composables/use-snackbar'
import { useAccountActivity } from '#composables/use-account-activity'

import type {
  AccountActivity_accountActivity,
  AccountActivity_accountActivity_PendingMomentComplete,
  AccountActivity_accountActivity_PendingMomentFailed,
  AccountActivity_accountActivity_PendingMomentProcessing,
  AccountActivity_accountActivity_PendingMomentPending,
  AccountActivity_accountActivity_PendingMomentDeploying,
} from '#graphql/types/AccountActivity'

const { addSnack, removeSnack } = useSnackbar()
const router = useRouter()
const { accountActivityOnResult } = useAccountActivity()
const { updateActiveMoment } = usePendingMoment()

const activity:Ref<(
  AccountActivity_accountActivity_PendingMomentComplete
  | AccountActivity_accountActivity_PendingMomentFailed
  | AccountActivity_accountActivity_PendingMomentProcessing
  | AccountActivity_accountActivity_PendingMomentPending
  | AccountActivity_accountActivity_PendingMomentDeploying
)[]> = ref([])

const expanded = ref(false)

//const isAllCompleted = computed(() => activity.value.every(item => item.__typename === 'TokenPurchaseSuccess' || item.__typename === 'TokenPurchaseFailed'))

const title = computed(() => {
  if (activity.value.length === 0) {
    return
  }
  /* Commenting out this logic for now incase we want to come back to this in the future */
  // if (isAllCompleted.value) {
  //   return 'Minting complete'
  // }

  // const inProgress = activity.value.filter(item => item.__typename === 'TokenPurchasePending' || item.__typename === 'TokenPurchaseConfirming')?.length

  // if (inProgress && activity.value.length > 1) {
  //   return `Minting ${inProgress}/${activity.value.length}`
  // }

  return 'Transaction summary'
})

// const formatAction = (action: AccountActivity_accountActivity['__typename']) => {
//   console.log('formatAction action=', action)
//   switch (action) {
//     case 'TokenPurchasePending':
//     default:
//       return 'Initiated purchase'
//     case 'TokenPurchaseConfirming':
//       return 'Verifying mint'
//     case 'TokenPurchaseFailed':
//       return 'Incomplete'
//     case 'TokenPurchaseSuccess':
//       return 'Complete'
//   }
// }

// const variables = ref({
//   id: accountId.value,
// })

// const variables = computed(() => ({
//   id: accountId.value,
// }))

// const {
//   onResult,
//   // restart,
//   // stop,
// } = useSubscription<AccountActivity, AccountActivityVariables>(ACCOUNT_ACTIVITY, variables.value, {
//   ready: isReady,
// })

// accountActivityOnResult.value((res) => {
//   const data = res?.data?.accountActivity

//   console.log('data', data)

// })

const handleAccountActivity = (data: AccountActivity_accountActivity) => {
  if (data) {
    const { __typename } = data

    // type narrowing to what's token-related only
    // won't accept array.includes
    if (__typename === 'PendingMomentComplete'
      || __typename === 'PendingMomentFailed'
      || __typename === 'PendingMomentProcessing'
      || __typename === 'PendingMomentDeploying'
      || __typename === 'PendingMomentPending') {
      if (__typename === 'PendingMomentDeploying' || __typename === 'PendingMomentPending' || __typename === 'PendingMomentProcessing') {
        const { pendingMomentCount } = data

        updateActiveMoment(pendingMomentCount || 0)
      }

      // if (__typename === 'PendingMomentPending') {
      //   const { pendingMomentCount } = data

      //   updateActiveMoment(pendingMomentCount || 0)
      // }

      // if (__typename === 'PendingMomentProcessing') {
      //   const { pendingMomentCount } = data

      //   updateActiveMoment(pendingMomentCount || 0)
      // }

      if (__typename === 'PendingMomentComplete') {
        const { id, pendingMomentId, pendingMomentCount } = data

        updateActiveMoment(pendingMomentCount || 0)

        addSnack({ message: 'Your moment is now live!', color: 'positive', action: (_, snackId) => handlePendingMomentComplete(id, pendingMomentId, snackId), actionLabel: 'View moment', time: 10000 })
      }

      if (__typename === 'PendingMomentFailed') {
        const { id, pendingMomentId, pendingMomentCount } = data

        updateActiveMoment(pendingMomentCount || 0)

        addSnack({ message: `Something went wrong while processing your moment.`, color: 'negative', action: (_, snackId) => handlePendingMomentFailed(id, pendingMomentId, snackId), actionLabel: 'View moment', time: 10000  })
      }
    }
  }
}

const removeActivityItem = (id: string, accountId: string) => {
  const match = activity.value.find(item => item.pendingMomentId === id && item.id === accountId)

  if (match) {
    const index = activity.value.indexOf(match)
    activity.value.splice(index, 1)
  }
}

const handlePendingMomentComplete = (accountId: string, pendingMomentId: string, snackId?: string) => {
  router.push({ name: 'home' })

  if (snackId) {
    removeSnack(snackId)
  }
}

const handlePendingMomentFailed = (accountId: string, pendingMomentId: string, snackId?: string) => {
  const routeLocation: {name: string, params: {profileSlug: string, dropId: string}, query?: {pending: string}} = {
    name: `moment-edit`,
    params: {
      profileSlug: accountId,
      dropId: pendingMomentId,
    },
  }

  routeLocation.query = {
    pending: 'true',
  }

  if (snackId) {
    removeSnack(snackId)
  }
  router.push(routeLocation)
}


const removeTokenActivityItem = (collectionId: string, tokenId: number) => {
  // const match = activity.value.find(item => item.collectionId === collectionId && item.tokenId === tokenId)
  // if (match) {
  //   const index = activity.value.indexOf(match)
  //   activity.value.splice(index, 1)
  // }
}

// const openEtherscan = (options: { hash: string, removeItem?: boolean, collectionId?: string, tokenId?: number }) => {
//   const { hash, removeItem, collectionId, tokenId } = options
//   if (removeItem  && collectionId && tokenId ) {
//     removeTokenActivityItem(collectionId, tokenId)
//   }

//   window.open(`${currentChainInfo.value.url}/tx/${hash}`, '_blank')
// }

// const navigateToNft = (collectionId: string, collectionType: string, communitySlug: string, tokenId: number,  snackId?: string) => {
//   // removeActivityItem(collectionId, tokenId)
//   if (collectionType === 'Single') {
//     router.push({ name: 'communityRewardToken', params: { id: communitySlug, rewardId: collectionId, tokenId } })
//   }

//   if (collectionType === 'REMX') {
//     router.push({ name: 'token', params: { collectionId, tokenId } })
//   }

//   if (snackId) {
//     removeSnack(snackId)
//   }
// }

const clearActivities = () => {
  activity.value = []
}

watch(accountActivityOnResult, (newAccountActivityOnResult) => {
  if (newAccountActivityOnResult) {
    handleAccountActivity(newAccountActivityOnResult)
  }
})
</script>

<style scoped>
.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(15px);
}
</style>
