<template>
  <div class="flex flex-row w-full h-full">
    <button
      v-if="showBackArrow"
      class="absolute translate-x-0 translate-y-0"
      @click="emit('back')"
    >
      <Icon
        icon="ArrowLeftIcon"
        class="transform transition-transform text-black"
        :class="!ignoreDarkMode && 'dark:text-dark-mode-white'"
      />
    </button>

    <div class="flex flex-col justify-between h-full w-full">
      <button
        v-if="!tapClicked"
        class="w-full h-full flex-row justify-center items-center"
        :class="{ [showBackground ? `bg-[#F1F5F9] ${!ignoreDarkMode ? 'dark:bg-black' : ''} rounded-lg border-[1px] border-[#CBD5E1] ${!ignoreDarkMode ? 'dark:border-dark-mode-border' : ''}` : '']: showBackground }"
        @click="handleTapClick"
      >
        <div class="flex flex-row justify-center items-center">
          <Body
            class="w-32 md:w-40"
            size="sm"
            :ignore-dark-mode="ignoreDarkMode"
          >
            Tap/click here to enter custom amount
          </Body>
        </div>
      </button>

      <div
        v-else
        class="flex items-center w-full h-full text-nowrap font-semibold text-center text-4xl text-black"
        :class="[
          !ignoreDarkMode && 'dark:text-dark-mode-white',
          { [showBackground ? `bg-[#F1F5F9] ${!ignoreDarkMode ? 'dark:bg-black' : ''} border-[1px] border-[#CBD5E1] ${!ignoreDarkMode ? 'dark:border-dark-mode-border' : ''} rounded-lg` : '']: showBackground }
        ]"
      >
        <textarea
          id="customTextArea"
          ref="customTextArea"
          data-test-id="textarea"
          class="truncate px-1.5 outline-none bg-transparent border-0 w-full text-center resize-none h-10 text-black"
          :class="!ignoreDarkMode && 'dark:text-dark-mode-white'"
          aria-multiline="false"
          maxlength="5"
          :value="`$` + customValue"
          v-bind="$attrs"
          aria-describedby="search-error"
          @input="validateCustomInput"
          @blur="handleBlurCustomInput"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, Ref, type PropType  } from 'vue'

import Icon from '#components/icon.vue'
import Body from '#components/typography/body'

const emit = defineEmits(['back', 'update'])

const props = defineProps({
  showBackArrow: {
    type: Boolean,
    default: true,
  },

  customAmount: {
    type: Number,
    default: 0,
  },

  customAmountIncrements: {
    type: Number,
    default: 0,
  },

  maxAmount: {
    type: Number,
    default: 0,
  },

  hasTapClicked: {
    type: Boolean,
    default: false,
  },

  chainId: {
    type: Object as PropType<number | null>,
    default: null,
  },

  showBackground: {
    type: Boolean,
    default: false,
  },

  ignoreDarkMode: {
    type: Boolean,
    default: false,
  },
})

const customValue = ref(props.customAmount)
const supportAmount = ref(props.customAmount)
const tapClicked = ref(props.hasTapClicked)
const customTextArea: Ref<HTMLTextAreaElement | null> = ref(null)

const handleTapClick = () => {
  tapClicked.value = true

  setTimeout(() => {
    if (customTextArea.value instanceof HTMLElement) {
      customTextArea.value.focus()
      emit('update', supportAmount.value)
    }
  }, 100)
}

const validateCustomInput = (event: Event) => {
  const input = event.target as HTMLTextAreaElement
  const value = input.value

  // Remove non-numeric characters
  const numericValue = value.replace(/\D/g, '')

  // Update the customValue with the numeric value
  customValue.value = Number(numericValue)

  // Update the input value with the numeric value
  input.value = numericValue

  if (numericValue === '0' || numericValue === '') {
    supportAmount.value = props.customAmountIncrements
    emit('update', supportAmount.value)

    return
  } else if (parseInt(value) > props.maxAmount) {
    customValue.value = props.maxAmount
    input.value = customValue.value.toString()
  }

  supportAmount.value = customValue.value
  emit('update', supportAmount.value)
}

const handleBlurCustomInput = (evt: Event) => {
  const input = evt.target as HTMLTextAreaElement
  const value = input.value

  if (value.length === 0 || value === '0' || value === '' || value === '$0') {
    customValue.value = props.customAmountIncrements
    supportAmount.value = customValue.value
    input.value = customValue.value.toString()

    return
  } else if (parseInt(value) > props.maxAmount) {
    customValue.value = props.maxAmount
    supportAmount.value = customValue.value
    input.value = customValue.value.toString()

    return
  }
}
</script>
