<template>
  <!-- desktop remx logo -->
  <svg
    width="80"
    height="18"
    viewBox="0 0 100 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="hidden md:block"
  >
    <path
      d="M6.21335 5.52629H6.3368C7.05003 3.99009 7.90042 2.89281 8.88798 2.23444C9.87553 1.57607 11.0551 1.24689 12.4267 1.24689C13.0851 1.24689 13.5377 1.30175 13.7846 1.41148V7.04876H13.62C13.1537 6.96647 12.701 6.92532 12.2621 6.92532C10.5065 6.89789 9.09372 7.43281 8.02387 8.53009C6.98145 9.59994 6.46024 11.2047 6.46024 13.3444V23.508H0V1.61722H6.21335V5.52629Z"
      :fill="color"
    />
    <path
      d="M27.0092 24.1663C25.2261 24.1663 23.6213 23.8783 22.1949 23.3022C20.7684 22.6987 19.5477 21.8758 18.5327 20.8334C17.5451 19.7909 16.777 18.5702 16.2284 17.1712C15.7072 15.7721 15.4466 14.2497 15.4466 12.6037C15.4466 10.9578 15.7209 9.43535 16.2695 8.03631C16.8182 6.60985 17.5863 5.37541 18.5738 4.33299C19.5614 3.29057 20.741 2.48133 22.1126 1.90526C23.4842 1.30175 25.0066 1 26.68 1C28.2436 1 29.6427 1.24689 30.8771 1.74066C32.139 2.23444 33.25 2.92024 34.2101 3.79807C35.4445 5.00507 36.3498 6.50012 36.9259 8.2832C37.5019 10.0663 37.7763 12.0414 37.7488 14.2085H21.7834C22.0028 15.8544 22.5515 17.1437 23.4293 18.0764C24.3071 19.0091 25.5279 19.4755 27.0915 19.4755C28.1065 19.4755 28.9294 19.2972 29.5604 18.9405C30.2187 18.5565 30.7262 18.0216 31.0828 17.3358H37.3373C37.063 18.2959 36.6241 19.2011 36.0206 20.0515C35.4445 20.8745 34.7176 21.6014 33.8398 22.2324C32.9894 22.8359 31.9881 23.3022 30.8359 23.6314C29.6838 23.988 28.4082 24.1663 27.0092 24.1663ZM26.68 5.56743C25.1987 5.56743 24.074 6.00635 23.3059 6.88417C22.5378 7.762 22.044 8.95529 21.8245 10.464H31.1651C31.0554 8.92786 30.6028 7.73456 29.8072 6.88417C29.0117 6.00635 27.9693 5.56743 26.68 5.56743Z"
      :fill="color"
    />
    <path
      d="M40.9873 1.61722H47.2007V5.03251H47.3241C48.0373 3.71577 48.9152 2.7145 49.9576 2.0287C51.0274 1.3429 52.3441 1 53.9078 1C56.7881 1 58.7907 2.38532 59.9154 5.15595H59.9977C60.9029 3.67462 61.9316 2.61849 63.0838 1.98755C64.2634 1.32918 65.5527 1 66.9517 1C69.3932 1 71.2311 1.74066 72.4655 3.22199C73.7 4.67589 74.3172 6.62356 74.3172 9.06501V23.508H67.8981V10.1349C67.8981 8.92786 67.6101 7.99517 67.034 7.3368C66.4854 6.651 65.6898 6.3081 64.6474 6.3081C63.4953 6.3081 62.5763 6.74701 61.8905 7.62483C61.2321 8.50266 60.903 9.64109 60.903 11.0401V23.508H54.4427V10.1349C54.4427 8.92786 54.1684 7.99517 53.6197 7.3368C53.0985 6.651 52.303 6.3081 51.2332 6.3081C50.081 6.3081 49.162 6.74701 48.4762 7.62483C47.7904 8.50266 47.4475 9.64109 47.4475 11.0401V23.508H40.9873V1.61722Z"
      :fill="color"
    />
    <path
      d="M84.6929 12.0277L77.7389 1.61722H85.1044L86.38 3.67462C86.6543 4.14097 86.9286 4.64846 87.203 5.1971C87.4773 5.71831 87.7242 6.21208 87.9436 6.67843C88.2179 7.22707 88.4648 7.77571 88.6843 8.32435H88.7666C88.986 7.77571 89.2329 7.22707 89.5073 6.67843C89.7267 6.21208 89.9599 5.71831 90.2068 5.1971C90.4811 4.64846 90.7417 4.14097 90.9886 3.67462L92.1819 1.61722H99.0125L92.3053 11.9042L100 23.508H92.5111L90.824 20.7099C90.5497 20.2436 90.2754 19.7361 90.001 19.1874C89.7267 18.6114 89.4798 18.0764 89.2604 17.5827C88.986 17.0066 88.7117 16.4305 88.4374 15.8544H88.3551L87.491 17.5827C87.2441 18.0764 86.9698 18.6114 86.668 19.1874C86.3937 19.7361 86.1057 20.2436 85.8039 20.7099L84.0346 23.508H76.9983L84.6929 12.0277Z"
      :fill="color"
    />
  </svg>

  <!-- mobile remx logo -->
  <svg
    width="70"
    height="17"
    viewBox="0 0 70 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="block md:hidden"
  >
    <path
      d="M4.34935 3.1684H4.43576C4.93502 2.09306 5.5303 1.32497 6.22158 0.864109C6.91287 0.403252 7.73857 0.172823 8.69869 0.172823C9.15955 0.172823 9.47639 0.211228 9.64921 0.288037V4.23413H9.534C9.20756 4.17653 8.89072 4.14772 8.58348 4.14772C7.35453 4.12852 6.3656 4.50297 5.61671 5.27106C4.88702 6.01996 4.52217 7.1433 4.52217 8.64109V15.7556H0V0.432055H4.34935V3.1684Z"
      :fill="logoColor"
    />
    <path
      d="M18.9064 16.2164C17.6583 16.2164 16.5349 16.0148 15.5364 15.6116C14.5379 15.1891 13.6834 14.613 12.9729 13.8833C12.2816 13.1537 11.7439 12.2991 11.3599 11.3198C10.995 10.3405 10.8126 9.27477 10.8126 8.12262C10.8126 6.97048 11.0046 5.90474 11.3887 4.92542C11.7727 3.92689 12.3104 3.06279 13.0017 2.33309C13.693 1.6034 14.5187 1.03693 15.4788 0.633679C16.4389 0.211226 17.5047 0 18.676 0C19.7705 0 20.7499 0.172821 21.614 0.518465C22.4973 0.864108 23.275 1.34417 23.9471 1.95865C24.8112 2.80355 25.4448 3.85009 25.8481 5.09824C26.2513 6.3464 26.4434 7.72897 26.4242 9.24596H15.2484C15.402 10.3981 15.786 11.3006 16.4005 11.9535C17.015 12.6064 17.8695 12.9328 18.964 12.9328C19.6745 12.9328 20.2506 12.808 20.6923 12.5584C21.1531 12.2895 21.5084 11.9151 21.758 11.435H26.1361C25.9441 12.1071 25.6369 12.7408 25.2144 13.3361C24.8112 13.9122 24.3023 14.421 23.6878 14.8627C23.0926 15.2851 22.3917 15.6116 21.5852 15.842C20.7787 16.0916 19.8858 16.2164 18.9064 16.2164ZM18.676 3.1972C17.6391 3.1972 16.8518 3.50444 16.3141 4.11892C15.7764 4.7334 15.4308 5.5687 15.2772 6.62483H21.8156C21.7388 5.5495 21.4219 4.71419 20.8651 4.11892C20.3082 3.50444 19.5785 3.1972 18.676 3.1972Z"
      :fill="logoColor"
    />
    <path
      d="M28.6911 0.432055H33.0405V2.82276H33.1269C33.6261 1.90104 34.2406 1.20015 34.9703 0.720091C35.7192 0.24003 36.6409 0 37.7354 0C39.7517 0 41.1535 0.969722 41.9408 2.90917H41.9984C42.6321 1.87224 43.3522 1.13294 44.1587 0.691288C44.9844 0.230429 45.8869 0 46.8662 0C48.5752 0 49.8618 0.518465 50.7259 1.5554C51.59 2.57312 52.022 3.93649 52.022 5.64551V15.7556H47.5287V6.39441C47.5287 5.5495 47.3271 4.89662 46.9238 4.43576C46.5398 3.9557 45.9829 3.71567 45.2532 3.71567C44.4467 3.71567 43.8034 4.02291 43.3233 4.63738C42.8625 5.25186 42.6321 6.04876 42.6321 7.02808V15.7556H38.1099V6.39441C38.1099 5.5495 37.9179 4.89662 37.5338 4.43576C37.169 3.9557 36.6121 3.71567 35.8632 3.71567C35.0567 3.71567 34.4134 4.02291 33.9334 4.63738C33.4533 5.25186 33.2133 6.04876 33.2133 7.02808V15.7556H28.6911V0.432055Z"
      :fill="logoColor"
    />
    <path
      d="M59.2851 7.71937L54.4172 0.432055H59.5731L60.466 1.87224C60.658 2.19868 60.8501 2.55392 61.0421 2.93797C61.2341 3.30282 61.4069 3.64846 61.5605 3.9749C61.7526 4.35895 61.9254 4.743 62.079 5.12705H62.1366C62.2902 4.743 62.4631 4.35895 62.6551 3.9749C62.8087 3.64846 62.9719 3.30282 63.1447 2.93797C63.3368 2.55392 63.5192 2.19868 63.692 1.87224L64.5273 0.432055H69.3087L64.6137 7.63296L70 15.7556H64.7577L63.5768 13.7969C63.3848 13.4705 63.1927 13.1153 63.0007 12.7312C62.8087 12.328 62.6359 11.9535 62.4823 11.6079C62.2902 11.2046 62.0982 10.8014 61.9062 10.3981H61.8486L61.2437 11.6079C61.0709 11.9535 60.8789 12.328 60.6676 12.7312C60.4756 13.1153 60.274 13.4705 60.0628 13.7969L58.8242 15.7556H53.8988L59.2851 7.71937Z"
      :fill="color"
    />
  </svg>
</template>

<script setup lang="ts">
import { type PropType, computed } from 'vue'

import type { Color } from '#types/remx-logo'

const props = defineProps({
  color: {
    type: String as PropType<Color>,
    default: 'dark-mode-white',
  },
})

const logoColor = computed(() => {
  switch (props.color) {
    case 'dark-mode-white':
    default:
      return '#f6f6f6'

    case 'white':
      return '#ffffff'

    case 'black':
      return '#000000'
  }
})
</script>
