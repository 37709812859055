<template>
  <Heading
    :level="level"
    :title-element="titleElement"
    :dark-background="(isDarkMode && !ignoreDarkMode) || darkBackground"
    :black-text="blackText"
    :ignore-dark-mode="ignoreDarkMode"
  >
    <slot />
  </Heading>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import type { TitleLevelType, TitleElementType } from './types'

import Heading from '#components/typography/heading'
import { useTheme } from '#composables/use-theme'

const { isDark:isDarkMode } = useTheme()

defineProps({
  level: {
    type: Number as PropType<TitleLevelType>,
    default: 2,
    validator: (value: TitleLevelType) => value >= 1 && value <= 4,
  },

  titleElement: {
    type: String as PropType<TitleElementType>,
    default: 'section-title',
    validator: (value: TitleElementType) => ['display-title', 'hero-title', 'leading-title', 'section-title', 'section-subtitle', 'item-title', 'small-title', 'full-base-title'].includes(value),
  },

  darkBackground: {
    type: Boolean,
    default: false,
  },

  blackText: {
    type: Boolean,
    default: false,
  },

  ignoreDarkMode: {
    type: Boolean,
    default: false,
  },
})
</script>
