<template>
  <button
    class="w-full flex gap-x-2 rounded-md"
    :disabled="$route.name === 'me-earnings'"
    @click="emit('click')"
  >
    <div
      class="w-full flex gap-x-2 p-2 rounded-md border-transparent items-center text-black dark:text-[#f6f6f6]"
      :class="[
        isActive ? 'cursor-default' : 'hover:bg-brand-bg-section dark:hover:bg-zinc-900',
        isActive ? 'bg-brand-bg-section dark:bg-zinc-900' : ''
      ]"
    >
      <img
        v-if="profileIcon && useProfileIcon"
        class="w-5 h-5 object-cover rounded-full dark:border dark:border-dark-mode-border"
        :src="getImage(profileIcon, { resize: { width: 100, fit: 'cover' } })"
      >

      <ProfileIcon
        v-else-if="!profileIcon && useProfileIcon"
        class="h-5 w-5 rounded-full dark:border dark:border-dark-mode-border"
      />

      <Icon
        v-else
        :class="[
          fillIcon ? 'fill-current' : isActive ? 'stroke-[2.5px]' : 'stroke-2',
          'text-black dark:text-[#f6f6f6]'
        ]"
        :icon="icon"
        class="h-5 w-5"
      />

      <span :class="[isActive ? 'font-medium' : '', 'text-black dark:text-[#f6f6f6]']">{{ text }}</span>
    </div>
  </button>
</template>

<script setup lang="ts">
import { PropType } from 'vue'

import useImage from '#composables/use-image'

import Icon from '#components/icon.vue'
import ProfileIcon from '#components/icons/profile-icon.vue'

defineProps({
  icon: {
    type: String as PropType<keyof typeof import('@creativelayer/chalk-icons')>,
    default: '',
  },

  text: {
    type: String,
    default: '',
  },

  fillIcon: {
    type: Boolean,
    default: false,
  },

  isActive: {
    type: Boolean,
    default: false,
  },

  useProfileIcon: {
    type: Boolean,
    default: false,
  },

  profileIcon: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['click'])

const { getImage } = useImage()

</script>
