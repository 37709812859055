<template>
  <button
    class="group flex flex-row gap-2 md:gap-3 items-center text-left w-full pt-4"
    :class="!ignoreDarkMode && 'dark:hover:bg-dark-mode-gray/10'"
    @click.prevent="navigateToProfile"
    @click.stop
  >
    <div
      class="aspect-square rounded-full w-8 h-8 md:w-12 md:h-12 overflow-hidden"
      :class="[
        'bg-brand-border',
        !ignoreDarkMode && 'dark:bg-dark-mode-border'
      ]"
    >
      <img
        v-if="icon"
        class="aspect-square w-8 h-8 md:w-12 md:h-12 group-hover:scale-110 transition-transform duration-300"
        :src="icon ? getImage(icon as string, {resize: {width: 128, height: 128, fit: 'cover'}}) : ''"
        alt="account image"
      >

      <img
        v-else
        class="h-9 w-9 md:w-12 md:h-12"
        :src="BlankProfileIcon"
      >
    </div>

    <div class="grow w-0">
      <Body
        size="sm"
        class="font-medium truncate"
        black-text
        :ignore-dark-mode="ignoreDarkMode"
      >
        {{ displayName }}
      </Body>

      <Body
        size="xs"
        class="truncate"
        :ignore-dark-mode="ignoreDarkMode"
      >
        @{{ slug }}
      </Body>

      <Body
        v-if="email"
        size="xs"
        class="truncate"
        :ignore-dark-mode="ignoreDarkMode"
      >
        {{ email }}
      </Body>
    </div>
  </button>
</template>

<script setup lang="ts">
import { defineProps, defineEmits } from 'vue'

import useImage from '#composables/use-image'
import { useTheme } from '#composables/use-theme'

import Body from '#components/typography/body'

import BlankProfileIcon from '#/assets/default_avatar.png'

defineProps({
  icon: {
    type: String,
    required: true,
  },

  displayName: {
    type: String,
    required: true,
  },

  slug: {
    type: String,
    required: true,
  },

  email: {
    type: String,
    default: '',
  },

  ignoreDarkMode: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['navigate'])

const { getImage } = useImage()
const { isDark: isDarkMode } = useTheme()

const navigateToProfile = () => {
  emit('navigate')
}
</script>
