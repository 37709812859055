import { ref } from 'vue'

const hasActivePendingMoment = ref(false)

export default function usePendingMoment () {
  const updateActiveMoment = (momentCount: number) => {
    if (momentCount > 0) {
      hasActivePendingMoment.value = true
    } else {
      hasActivePendingMoment.value = false
    }
  }

  return {
    hasActivePendingMoment,
    updateActiveMoment,
  }
}
