<template>
  <div class="p-6 md:p-10">
    <div class="flex w-full flex-row justify-between h-6">
      <Body
        class="font-medium"
        size="xs"
        disabled-text
        :ignore-dark-mode="ignoreDarkMode"
      >
        ACCOUNTS
      </Body>

      <LoadingIcon
        is-dark
        size="sm"
        class="transition-opacity opacity-0 duration-200"
        :class="searchLoading && 'opacity-100'"
      />
    </div>

    <div
      v-if="searchLoading && searchResults.length === 0"
      class="flex w-full flex-col space-y-1"
    >
      <SearchSkeletonLoader :loading="true" />
    </div>

    <div v-else-if="searchResults.length > 0">
      <ul class="text-black space-y-1">
        <li class="w-full">
          <SearchAccountCard
            v-for="user in searchResults"
            :key="user.id"
            :icon="user.icon || ''"
            :display-name="user.username || ''"
            :slug="user.slug"
            :email="user.email || ''"
            :ignore-dark-mode="ignoreDarkMode"
            @navigate="()=> emit('select', isSponsorForm ? {id: user.id, slug: user.slug, username: user.username, icon:user.icon} : user.slug as string)"
          />
        </li>
      </ul>
    </div>

    <div
      v-else-if="searchText.length > 0"
      class="w-full text-black text-sm md:text-base pt-4"
    >
      We couldn’t find any accounts that matched <span class="break-all italic">{{ searchText }}.</span> Please try again.
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted } from 'vue'

import { useLazyQuery } from '#composables/use-apollo'

import SearchAccountCard from '#components/search/search-account-card.vue'
import Body from '#components/typography/body'
import SearchSkeletonLoader from '#components/search/search-skeleton-loader.vue'
import LoadingIcon from '#components/icons/loading-icon.vue'

import { SEARCH_ACCOUNTS_BY_USERNAME } from '#queries/SearchAccountsByUsername'
import type { SearchAccountsByUsername, SearchAccountsByUsernameVariables, SearchAccountsByUsername_searchAccountsByUsername_accounts } from '#graphql/types/SearchAccountsByUsername'

const emit = defineEmits(['select'])

const props = defineProps({
  searchText: {
    type: String,
    required: true,
  },

  isSponsorForm: {
    type: Boolean,
    default: false,
  },

  ignoreDarkMode: {
    type: Boolean,
    default: false,
  },
})

const searchResults = ref<SearchAccountsByUsername_searchAccountsByUsername_accounts[]>([])
const searchLoading = ref(false)
const multipleLetterSearch = ref(false)

const {
  load: searchAccountsByUsername,
} = useLazyQuery<SearchAccountsByUsername, SearchAccountsByUsernameVariables>(SEARCH_ACCOUNTS_BY_USERNAME)

const handleSearchText = async () => {
  const input = {  input:{ searchText:props.searchText as string }  }

  try {
    searchLoading.value = true

    if (props.searchText.length > 1) {
      multipleLetterSearch.value = true
    }

    const result = await searchAccountsByUsername(input, { fetchPolicy: 'network-only' })

    if (multipleLetterSearch.value && props.searchText.length <= 1) {
      multipleLetterSearch.value = false
    }

    searchLoading.value = false

    if (result.data?.searchAccountsByUsername) {
      searchResults.value= result.data.searchAccountsByUsername.accounts as SearchAccountsByUsername_searchAccountsByUsername_accounts[]
    }
  } catch (error) {
    searchLoading.value = false
    console.log('error', error)
  }
}

onMounted(() => {
  if (props.searchText.length > 0) {
    handleSearchText()
  }
})

watch(() => props.searchText, (newSearchText) => {
  if (newSearchText && newSearchText.length > 0) {
    handleSearchText()
  } else {
    searchResults.value = []
  }
})
</script>
