import { gql } from '@apollo/client/core'

/**
 * Gets all drops for a profiel.
 */
export const GET_PENDING_MOMENTS = gql`
  query GetPendingMoments($input: GetPendingMomentsInput!) {
    getPendingMoments(input: $input) {
      id
      status
      created
      updated
      accountId
      name
      description
      icon
      collectionMetadata
      benefitMetadata
      chainId
      dropType
      categories
      price
      statusMessage
    }
  }
`
