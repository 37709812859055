<template>
  <svg
    width="26"
    height="25"
    viewBox="0 0 26 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.41814 0H21.0243V24.4598H18.5867V13.2556H18.5628C18.2934 10.159 15.7809 7.73244 12.7212 7.73244C9.66154 7.73244 7.14905 10.159 6.87965 13.2556H6.85574V24.4598H4.41814V0Z"
      :fill="lightBackground ? (ignoreDarkMode ? 'black' : (isDarkMode ? '#f6f6f6' : 'black')) : (ignoreDarkMode ? 'white' : (isDarkMode ? 'black' : 'white'))"
    />
    <path
      d="M0 3.47171L0.990273 6.94341H1.8282V20.988C1.40749 20.988 1.06645 21.3413 1.06645 21.7771V22.7239H0.914098C0.493396 22.7239 0.15235 23.0772 0.15235 23.5129V24.4598H8.68394V23.5129C8.68394 23.0772 8.34289 22.7239 7.92219 22.7239H7.76984V21.7771C7.76984 21.3413 7.42879 20.988 7.00809 20.988H6.09399V3.47171H0Z"
      :fill="lightBackground ? (ignoreDarkMode ? 'black' : (isDarkMode ? '#f6f6f6' : 'black')) : (ignoreDarkMode ? 'white' : (isDarkMode ? 'black' : 'white'))"
    />
    <path
      d="M18.739 20.988C18.3183 20.988 17.9773 21.3413 17.9773 21.7771V22.7239H17.8249C17.4042 22.7239 17.0632 23.0772 17.0632 23.5129V24.4598H25.5948V23.5129C25.5948 23.0772 25.2537 22.7239 24.833 22.7239H24.6807V21.7771C24.6807 21.3413 24.3396 20.988 23.9189 20.988V6.94341H24.7568L25.7471 3.47171H19.6531V20.988H18.739Z"
      :fill="lightBackground ? (ignoreDarkMode ? 'black' : (isDarkMode ? '#f6f6f6' : 'black')) : (ignoreDarkMode ? 'white' : (isDarkMode ? 'black' : 'white'))"
    />
  </svg>
</template>

<script setup lang="ts">
import { useTheme } from '#composables/use-theme'

const { isDark: isDarkMode } = useTheme()

defineProps({
  lightBackground: {
    type: Boolean,
    default: false,
  },

  ignoreDarkMode: {
    type: Boolean,
    default: false,
  },
})
</script>
