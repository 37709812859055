<template>
  <div
    data-test-id="root"
    class="relative"
    :class="[
      (!isValid && isDirty || hasError) && showValidation ? 'border-brand-error' : '',
      fullWidth ? 'w-full' : 'w-full md:w-auto',
    ]"
  >
    <label
      v-if="label"
      data-test-id="label"
      :for="id"
      :class="[
        isDisabled ? 'cursor-not-allowed' : 'cursor-pointer',
        label ? 'flex items-center mb-2 leading-none select-none' : 'hidden'
      ]"
    >
      <Title
        title-element="item-title"
        :ignore-dark-mode="ignoreDarkMode"
      >
        {{ label }}
      </Title>

      <span
        v-if="$attrs.required !== undefined"
        data-test-id="asterisk"
        class="text-brand-error"
      >
        *
      </span>

      <LoadingIcon
        v-show="loading"
        is-dark
        class="ml-2 inline"
      />
    </label>

    <input
      :id="id"
      ref="element"
      data-test-id="input"
      :placeholder="placeholder"
      class="relative py-2 px-4 focus:ring-1 outline-none transition-all border-2 border-brand-border placeholder:text-base lg:placeholder:text-xl text-base lg:text-xl"
      :class="[
        (!isValid && isDirty || hasError) && showValidation? 'border-brand-error focus:ring-brand-error focus:border-brand-error' : !footer && (!darkBackground && !isDarkMode) ? 'focus:ring-black focus:border-black ' : ` ${!ignoreDarkMode ? 'dark:focus:border-black focus:border-white' : 'border-brand-border focus:border-black'} ring-white ${!ignoreDarkMode ? 'dark:ring-dark-mode-gray' : ''}`,
        isDisabled && 'cursor-not-allowed',
        !isDisabled && footer ? 'bg-black text-white' : isDisabled ? 'bg-brand-bg-section text-brand-disabled-copy' : `bg-white ${!ignoreDarkMode ? 'dark:text-black' : ''}`,
        footer ? 'h-full ' : '',
        darkBackground ? 'placeholder:text-brand-placeholder-dark bg-transparent text-white' : 'placeholder:text-brand-placeholder-light',
        price ? 'pl-7 lg:pl-8': '',
        fullWidth ? 'w-full' : 'w-full md:w-auto',
        !ignoreDarkMode && 'dark:border-dark-mode-border'
      ]"
      :value="modelValue"
      v-bind="$attrs"
      :aria-describedby="`${id}-error`"
      :aria-invalid="!isValid"
      @input="handleInput"
      @focus="focus"
      @blur="blur"
    >

    <p
      v-if="price"
      class="absolute top-2.5 left-4 lg:text-xl"
      :class="[
        modelValue.length ? (!ignoreDarkMode ? 'dark:text-black' : '') : 'text-brand-placeholder-light'
      ]"
    >
      $
    </p>

    <p
      v-if="price"
      class="absolute top-2.5 right-4 lg:text-xl"
      :class="[
        modelValue.length ? (!ignoreDarkMode ? 'dark:text-black' : '') : 'text-brand-placeholder-light'
      ]"
    >
      USD
    </p>

    <transition
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition duration-150 ease-in"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-if="(!isValid && isDirty || hasError) && showValidation"
        :id="`${id}-error`"
        data-test-id="error"
        class="absolute bottom-0 right-0 mr-2 px-1 bg-white text-xs transform-gpu translate-y-1/2 text-red-500"
        role="alert"
        :class="[
          footer ? 'bg-black' : `bg-white ${!ignoreDarkMode ? 'dark:border dark:border-dark-mode-border/20' : ''}`
        ]"
      >
        {{ message }}
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
}
</script>

<script setup lang="ts">
import { ref, type Ref, computed, watch, useAttrs, onMounted } from 'vue'

import { useTheme } from '#composables/use-theme'

import { isValidLink } from '#utils/formatting'

import LoadingIcon from '#components/icons/loading-icon.vue'
import Title from '#components/typography/title'

const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },

  errorMessage: {
    type: String,
    default: 'This field is invalid',
  },

  placeholder: {
    type: String,
    required: true,
  },

  label: {
    type: String,
    default: '',
  },

  id: {
    type: String,
    required: true,
  },

  footer: {
    type: Boolean,
    default: false,
  },

  darkBackground: {
    type: Boolean,
    default: false,
  },

  loading: {
    type: Boolean,
    default: false,
  },

  price: {
    type: Boolean,
    default: false,
  },

  showValidation: {
    type: Boolean,
    default: true,
  },

  hasError: {
    type: Boolean,
    default: false,
  },

  showCustomErrorForBlankInput: {
    type: Boolean,
    default: false,
  },

  fullWidth: {
    type: Boolean,
    default: true,
  },

  ignoreDarkMode: {
    type: Boolean,
    default: false,
  },
})

const attrs = useAttrs()
const { isDark: isDarkMode } = useTheme()

const emit = defineEmits(['update:modelValue', 'valid', 'focus', 'blur'])

const element: Ref<HTMLInputElement | null> = ref(null)
const isDirty = ref(props.modelValue !== '')
const isValid = ref(true)
const hasFocus = ref(false)

const isDisabled = computed(() => attrs.disabled && attrs.disabled !== undefined)
const message = computed(() => (props.modelValue === '' && !props.showCustomErrorForBlankInput) ? 'This field is required' : props.errorMessage)

watch(
  () => props.modelValue,
  () => checkValidity(),
  { flush: 'post' }
)

const handleInput = (evt: Event) => {
  const value = (evt.target as HTMLInputElement).value

  emit('update:modelValue', value)
  setDirty()
  checkValidity()
}

const setDirty = () => {
  if (!isDirty.value) {
    isDirty.value = true
  }
}

const checkValidity = () => {
  if (attrs.type === 'advanced-url') {
    isValid.value = isValidLink((element.value as HTMLInputElement).value)
  } else if (attrs.type === 'email') {
    const validateEmail = (email) => {
      return email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    }

    const email = (element.value as HTMLInputElement).value
    isValid.value = email === '' || validateEmail(email)
  } else if (props.price) {
    const validatePrice = (price) => {
      return price.match(
        /^(\d{1,3})?(,?\d{3})*(\.\d{1,2})?$/
      )
    }

    const price = (element.value as HTMLInputElement).value
    isValid.value = price === '' || (validatePrice(price) && parseFloat(price) >= 0.5)
  } else {
    isValid.value = (element.value as HTMLInputElement).checkValidity()
  }

  emit('valid', isValid.value)
}

const focus = () => {
  hasFocus.value = true
  emit('focus')
}

const blur = () => {
  hasFocus.value = false
  emit('blur', isValid.value, props.id)
}

onMounted(() => checkValidity())
</script>
